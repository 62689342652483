body {
  margin: 0;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*#root {*/
/*  padding: 0px!important;*/
/*  margin: 0px!important;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
Workaround to get rid of annoying highlight from react-slick
*/
.slick-slide div {
  outline: none;
}

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}